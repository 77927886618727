import type { Composer, I18n, I18nMode, I18nOptions, Locale, VueI18n } from 'vue-i18n';
import { createI18n } from 'vue-i18n';

import { isRef, nextTick } from 'vue';
import axios from 'axios';

const imports: Record<Locale, any> = {
  en: import.meta.glob(`/src/**/lang/en.json`, {
    eager: true,
    import: 'default',
  }),
  nl: import.meta.glob(`/src/**/lang/nl.json`, {
    eager: true,
    import: 'default',
  }),
};

const locales = Object.keys(imports);

const getLocaleMessages = () =>
  locales.reduce(
    (messages, locale) => ({
      ...messages,
      [locale]: Object.values(imports[locale]).reduce((message: any, current: any) => ({ ...message, ...current }), {}),
    }),
    {},
  );

export const SUPPORT_LOCALES = ['nl'];

export const i18n = createI18n({
  legacy: false,
  locale: 'nl',
  fallbackLocale: 'en',
  // remove next two lines to find missing keys
  // fallbackWarn: false,
  // missingWarn: false,
  messages: getLocaleMessages() || [],
});

function isComposer(instance: VueI18n | Composer, mode: I18nMode): instance is Composer {
  return mode === 'composition' && isRef(instance.locale);
}

export function getLocale(i18n: I18n): string {
  if (isComposer(i18n.global, i18n.mode)) {
    return i18n.global.locale.value;
  } else {
    return i18n.global.locale;
  }
}

export function setLocale(i18n: I18n, locale: Locale): void {
  if (isComposer(i18n.global, i18n.mode)) {
    i18n.global.locale.value = locale;
  } else {
    i18n.global.locale = locale;
  }
}

export function setupI18n(options: I18nOptions = { locale: 'en' }): I18n {
  const i18n = createI18n(options);
  setI18nLanguage(i18n, options.locale!);
  return i18n;
}

export function setI18nLanguage(i18n: I18n, locale: Locale): void {
  setLocale(i18n, locale);

  axios.defaults.headers.common['Accept-Language'] = locale;

  document.querySelector('html')!.setAttribute('lang', locale);
}

export async function loadLocaleMessages(i18n: I18n, locale: Locale) {
  // load locale messages
  const messages = import.meta?.glob(`./locales/${locale}.json`);

  // set locale and locale message
  i18n.global.setLocaleMessage(locale, messages);

  return nextTick();
}
