import axios, { AxiosError, type AxiosInstance, type AxiosRequestConfig, type AxiosResponse } from 'axios';

const baseURL = `${import.meta.env.VITE_BACKEND_URL}`;

export const axiosInstance: AxiosInstance = axios.create({
  baseURL,
  timeout: 10000,
  withCredentials: true,
  withXSRFToken: true,
});

const errorCodeMap: { [key: number]: (error: AxiosError) => void } = {
  400: () => 'Bad Request: The server cannot process the request due to a client error.',
  401: () => 'Unauthorized: Authentication is required to access this resource.',
  403: () => 'Forbidden: The server understood the request, but refuses to authorize it.',
  404: () => 'Not Found: The requested resource could not be found.',
};

function responseErrors(error: AxiosError) {
  if (error.response && error.response.status && errorCodeMap[error.response.status]) {
    errorCodeMap[error.response.status](error);
  } else {
    console.error('Response error:', error);
  }

  return Promise.reject(error);
}

axiosInstance.interceptors.request.use(
  (config) => config,
  (error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => responseErrors(error),
);

export const api = {
  get: <T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> => axiosInstance.get<T>(url, config),
  post: <T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> => axiosInstance.post<T>(url, data, config),
  patch: <T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> => axiosInstance.patch<T>(url, data, config),
  put: <T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> => axiosInstance.put<T>(url, data, config),
  delete: <T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> => axiosInstance.delete<T>(url, config),
};
