type GoogleMapsConfig = {
  key: string;
  v: string;
  [key: string]: string;
};

export function loadGoogleAPI(config: GoogleMapsConfig): void {
  let scriptLoadPromise: Promise<void> | undefined;
  let scriptElement: HTMLScriptElement;
  let paramKey: string;

  const apiName = 'The Google Maps JavaScript API';
  const googleObjectName = 'google';
  const importLibraryFunctionName = 'importLibrary';
  const callbackName = '__ib__';
  const documentObject = document;

  let windowObject = window as any;

  windowObject = windowObject[googleObjectName] || (windowObject[googleObjectName] = {});

  const googleMapsObject = windowObject.maps || (windowObject.maps = {});
  const requestedLibraries = new Set<string>();
  const urlParams = new URLSearchParams();

  const loadScript = (): Promise<void> =>
    scriptLoadPromise ||
    (scriptLoadPromise = new Promise<void>((resolve, reject) => {
      scriptElement = documentObject.createElement('script');
      urlParams.set('libraries', [...requestedLibraries].join(','));

      for (paramKey in config) {
        urlParams.set(
          paramKey.replace(/[A-Z]/g, (match) => '_' + match[0].toLowerCase()),
          config[paramKey],
        );
      }

      urlParams.set('callback', `${googleObjectName}.maps.${callbackName}`);
      scriptElement.src = `https://maps.${googleObjectName}apis.com/maps/api/js?` + urlParams.toString();
      googleMapsObject[callbackName] = resolve;
      scriptElement.onerror = () => {
        scriptLoadPromise = undefined;
        reject(new Error(apiName + ' could not load.'));
      };
      scriptElement.nonce = documentObject.querySelector<HTMLScriptElement>('script[nonce]')?.nonce || '';
      documentObject.head.appendChild(scriptElement);
    }));

  if (googleMapsObject[importLibraryFunctionName]) {
    console.warn(apiName + ' only loads once. Ignoring:', config);
  } else {
    googleMapsObject[importLibraryFunctionName] = async (libraryName: string, ...additionalArgs: any[]) => {
      requestedLibraries.add(libraryName);
      await loadScript();
      return googleMapsObject[importLibraryFunctionName](libraryName, ...additionalArgs);
    };
  }
}
